//@flow
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { enqueueSnackbar } from "notistack";
import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next";
import SkeletonLoaderWrapper from "../../components/mui/general/skeletonLoaderWrapper/skeletonLoaderWrapper";
import { muiDatePickersLocales } from "../../helpers/mui/muiLocales";
import { PAGE_LOGIN } from "../../locales/pages/namespaces";

import UnauthenticatedRouter from "../../routers/unauthenticatedRouter";
import Content from "./content";

import useUserData from "../../hooks/user/useUserData";
import useUserAction from "../../hooks/user/useUserAction";

import dayjs from "dayjs";
import 'dayjs/locale/el'

const Main = () => {
	const { isLoggedIn, language, loading, loadingITYE, message } = useUserData()
	const { t } = useTranslation(PAGE_LOGIN)
	dayjs.locale(language);
	const { getUserPermissions, getUserDetails, loginToITYEApi, setUserMessage } = useUserAction()
	
	useEffect(() => {
		if (isLoggedIn) {
			getUserPermissions()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoggedIn])
	
	useEffect(() => {
		if (isLoggedIn) {
			// getUserDetails() //TODO::Uncomment when API is ready
		}
	}, [getUserDetails, isLoggedIn])
	
	useEffect(() => {
		if(isLoggedIn) {
			// TODO::Κανονικά τα credentials δεν θα πρέπει να τα φυλάμε στην javascript. Πρέπει να έρχονται από τον server.
			loginToITYEApi({
				username: 'ergologic',
				password: 'NKsTYCdcxq'
			})
		}
	}, [isLoggedIn, loginToITYEApi])
	
	useEffect(() => {
		if (message) {
			enqueueSnackbar(t(message?.text), {
					variant: message?.variant,
					autoHideDuration: null
				}
			)
			setUserMessage(null)
		}
	}, [message, setUserMessage, t])
	
	if (!isLoggedIn) return (
		<LocalizationProvider
			dateAdapter={AdapterDayjs}
			localeText={muiDatePickersLocales(language)}
		>
			<UnauthenticatedRouter/>
		</LocalizationProvider>
	)
	
	return (
		<SkeletonLoaderWrapper loading={loading && loadingITYE}>
			<LocalizationProvider
				dateAdapter={AdapterDayjs}
				localeText={muiDatePickersLocales(language)}
			>
				<Content/>
			</LocalizationProvider>
		</SkeletonLoaderWrapper>
	)
}

export default Main