import * as Namespaces from './namespaces'

const locales = {
	[Namespaces.PAGE_LOGIN]: {
		el: {
			'Connection': 'Σύνδεση',
			'Connection With Taxisnet' : 'Σύνδεση με κωδικούς Taxisnet',
			'Enter your username': 'Εισάγετε το όνομα χρήστη',
			'Password': 'Κωδικός',
			'Login': 'Είσοδος',
			'required': 'Απαιτείται',
			'Forgot password? Click here.': 'Ξεχάσατε τον κωδικό σας; Πατήστε εδώ.',
			'Cancel': 'Ακύρωση',
			'Password reset': 'Ανάκτηση Κωδικού',
			'reset_password': 'Για να δηλώσετε νέο κωδικό, παρακαλώ εισάγετε το email σας εδώ. Θα λάβετε στο email σας έναν εξαψήφιο κωδικό. <br/> Χρησιμοποιείστε τον εξαψήφιο κωδικό για να δηλώσετε τον νέο κωδικό σας.',
			"Not Found": "Το email που καταχωρήθηκε δεν υπάρχει.",
			'reset_success': 'Ο κωδικός σας έχει αλλάξει επιτυχώς. Προχωρήστε στη σύνδεση με τον νέο κωδικό σας.',
			'request_new_pass_success': 'Ο εξαψήφιος κωδικός έχει αποσταλεί επιτυχώς στο email σας. Αν δεν βλέπετε το email, παρακαλώ ελέγξτε τους φακέλους των SPAM ή JUNK emails.',
			'Password must be between 7-16 latin characters and contain at least 3 of the following. -Upper case character -Lower case character -Numeric digit -Special character': 'Ο κωδικός πρόσβασης πρέπει να αποτελείται από 7-16 λατινικούς χαρακτήρες και να περιέχει τουλάχιστον 3 από τα ακόλουθα. -Κεφάλαιο -Πεζό χαρακτήρα -Αριθμητικό ψηφίο -Ειδικός χαρακτήρας',
			'Passwords must match': 'Οι κωδικοί πρέπει να ταιριάζουν',
			'Six digit code': 'Εξαψήφιος κωδικός',
			'New password': 'Νέος κωδικός',
			'Confirm password': 'Επαλήθευση κωδικού',
			'Submit New Password': 'Υποβολή Νέου Κωδικού',
			'Send 6 Digit Code': 'Αποστολή Εξαψήφιου Κωδικού',
			'Connection to API failed!': 'Η επικοινωνία με το API απέτυχε!',
			'Insert a valid email': 'Εισάγετε ένα έγκυρο email',
			'Wrong 6 digit password!': 'Μη έγκυρος ή ληγμένος εξαψήφιος κωδικός!',
			'Enter your email': 'Εισαγωγή email',
			'msg.login_notfound' : 'Λανθασμένα στοιχεία πρόσβασης',
			'Unable to connect to ITYE API wrong credentials' : 'Αδυναμία σύδεσης στο ITYE API λόγω λανθασμένων διαπιστευτηρίων',
		},
		en: {
			"Not Found": "Email not found.",
			'reset_success': 'Password reset successful. Please proceed to login.',
			'request_new_pass_success': 'The 6 digit code was successfully send to your email. If you cannot find this email, please check SPAM or JUNK folders in your email provider.',
			'required': 'Required',
			'msg.login_notfound' : 'Incorrect Login Credentials',
		}
	},
	[Namespaces.PAGE_HOME]: {
		el: {
			'Username': 'Όνομα Χρήστη',
			'My Profile': 'Το Προφίλ μου'
		},
		en: {}
	},
	[Namespaces.PAGE_CONTACT]: {
		el: {
			'Contact': 'Επικοινωνία'
		},
		en: {}
	},
	[Namespaces.PAGE_PRIVACY]: {
		el: {
			'Privacy Policy': 'Πολιτική Απορρήτου',
			'Personal Data Protection': 'Προστασία Προσωπικών Δεδομένων',
			'privacy_text': 'Περισσότερα για την πολιτική απορρήτου εδώ..'
		},
		en: {
			'privacy_text': 'More about privacy here..'
		}
	},
}

export default locales