import * as Namespaces from './namespaces'

const locales = {
	[Namespaces.PARAMETERS]: {
		el: {
			'Actions':'Ενέργειες',
			'Add' : 'Προσθήκη',
			'Edit' : 'Επεξεργασία',
			'Sch. Year' : 'Σχ. χρονιά',
			'Default' : 'Προεπιλογή',
			'Sch. Years' : 'Σxολικές χρονιές',
			'School Year created successfully!' : 'Η σχολική χρονιά δημιουργήθηκε με επιτυχία!',
			'School Year deleted successfully!' : 'Η σχολική χρονιά διαγράφηκε με επιτυχία',
			'School Year updated successfully!' : 'Η σχολική χρονιά ενημερώθηκε με επιτυχία',
			'An error occurred!' : 'Παρουσιάστηκε σφάλμα!',

			
		},
		en: {
		
		}
	},
}

export default locales