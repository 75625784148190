// @flow
import { Capacitor } from "@capacitor/core";
import { Save } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import * as React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import './routesMap.css';
import { useTranslation } from "react-i18next";
import PageContentWrapper from "../../../../components/mui/general/pageContentWrapper/pageContentWrapper";
import PageTitle from "../../../../components/mui/general/pageTitle/pageTitle";
import { API } from "../../../../helpers/constants";
import useUserData from "../../../../hooks/user/useUserData";
import { PARENTS } from "../../locales/namespaces";

type Props = {};

// Set the initial center of the map
const initialCenter = {
	lat: 38.2466,   //Patra
	lng: 21.7346 //
};

let API_KEY = '';
if (Capacitor.getPlatform() === 'android') {
	API_KEY = 'AIzaSyDXX14_M2rKeE_9FQ18Y0AmPSbOXFwGWoI'
} else {
	API_KEY = 'AIzaSyBKmIV9woBQHDCCJg0EgUKj_RRZoLW1x5w'
}

// Set the container size for the map
const containerStyle = {
	minWidth: '100%', height: '450px'
};

export const RoutesMap = (props: Props) => {
	const {
		studentName,
		studentInfo,
		item,
		afm,
		routeType, draggableValue, onClose
	} = props;
	const { token } = useUserData();
	const { t } = useTranslation(PARENTS);
	const mapRef = useRef(null);
	
	//selected home address - green pin
	const [selectedLat, setSelectedLat] = useState(null);
	const [selectedLng, setSelectedLng] = useState(null);
	const [selectedAddress, setSelectedAddress] = useState(null);
	const [markers, setMarkers] = useState([]);
	
	console.log(token);
	useEffect(() => {
		console.log(afm,studentName);
		axios({
			method: 'post',
			data: { afm: afm, studentName: studentName },
			headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
			url: `${API}/el/pde/check/maps`,
		}).then(response => {
			const data = response.data
			console.log('data', data);
			if (data.length > 0) {
				setSelectedAddress(data[0]);
			}
			if (data.length > 0 && data[0] && studentInfo) {
				console.log([
					{
						position: { 'lat': Number(data[0]?.newLat), 'lng': Number(data[0]?.newLng) },
						// label: studentInfo?.stud_address,
						// title: studentInfo?.stud_address,
						label: t('Selected home address'),
						title:t('Selected home address'),
						url: `https://maps.google.com/mapfiles/ms/icons/green.png`,
						draggable: false
					},
					{
						position: { 'lat': studentInfo?.stud_lat, 'lng': studentInfo?.stud_lon },
						label: studentInfo?.stud_address,
						title: studentInfo?.stud_address,
						url: `https://maps.google.com/mapfiles/ms/icons/blue.png`,
						draggable: true
					},
					{
						position: { 'lat': item?.busstop_in_lat, 'lng': item?.busstop_in_lon },
						label: item?.busstop_in,
						title: item?.busstop_in,
						url: `https://maps.google.com/mapfiles/ms/icons/red.png`,
						draggable: false
					},
					{
						position: { 'lat': item?.busstop_out_lat, 'lng': item?.busstop_out_lon },
						label: item?.busstop_out,
						title: item?.busstop_out,
						url: `https://maps.google.com/mapfiles/ms/icons/red.png`,
						draggable: false
					}
				]);
				setMarkers([
					{
						position: { 'lat': Number(data[0]?.newLat), 'lng': Number(data[0]?.newLng) },
						// label: studentInfo?.stud_address,
						// title: studentInfo?.stud_address,
						label: t('Selected home address'),
						title:t('Selected home address'),
						url: `https://maps.google.com/mapfiles/ms/icons/green.png`,
						draggable: false
					},
					{
						position: { 'lat': studentInfo?.stud_lat, 'lng': studentInfo?.stud_lon },
						label: studentInfo?.stud_address,
						title: studentInfo?.stud_address,
						url: `https://maps.google.com/mapfiles/ms/icons/blue.png`,
						draggable: true
					},
					{
						position: { 'lat': item?.busstop_in_lat, 'lng': item?.busstop_in_lon },
						label: item?.busstop_in,
						title: item?.busstop_in,
						url: `https://maps.google.com/mapfiles/ms/icons/red.png`,
						draggable: false
					},
					{
						position: { 'lat': item?.busstop_out_lat, 'lng': item?.busstop_out_lon },
						label: item?.busstop_out,
						title: item?.busstop_out,
						url: `https://maps.google.com/mapfiles/ms/icons/red.png`,
						draggable: false
					}
				])
			} else if (data.length === 0 && studentInfo) {
				setMarkers([
					{
						position: { 'lat': studentInfo?.stud_lat, 'lng': studentInfo?.stud_lon },
						label: studentInfo?.stud_address,
						title: studentInfo?.stud_address,
						url: `https://maps.google.com/mapfiles/ms/icons/blue.png`,
						draggable: true
					},
					{
						position: { 'lat': item?.busstop_in_lat, 'lng': item?.busstop_in_lon },
						label: item?.busstop_in,
						title: item?.busstop_in,
						url: `https://maps.google.com/mapfiles/ms/icons/red.png`,
						draggable: false
					},
					{
						position: { 'lat': item?.busstop_out_lat, 'lng': item?.busstop_out_lon },
						label: item?.busstop_out,
						title: item?.busstop_out,
						url: `https://maps.google.com/mapfiles/ms/icons/red.png`,
						draggable: false
					}
				])
			} else {
				setMarkers([
					{
						position: { 'lat': item?.busstop_in_lat, 'lng': item?.busstop_in_lon },
						label: item?.busstop_in,
						title: item?.busstop_in,
						url: `https://maps.google.com/mapfiles/ms/icons/red.png`,
						draggable: false
					}, // Marker 1: San Francisco
					{
						position: { 'lat': item?.busstop_out_lat, 'lng': item?.busstop_out_lon },
						label: item?.busstop_out,
						title: item?.busstop_out,
						url: `https://maps.google.com/mapfiles/ms/icons/red.png`,
						draggable: false
					}
				])
			}
			
		}).catch(error => {
		
		})
	}, [studentName,afm])


	const [zoom, setZoom] = useState(6);  // Initial zoom level
	
	let defaultMarkers = [];
	if (studentInfo === null) {
		defaultMarkers = [
			{
				lat: item?.busstop_in_lat,
				lng: item?.busstop_in_lon,
			},
			{
				lat: item?.busstop_out_lat,
				lng: item?.busstop_out_lon,
			}
		];
		
	} else {
		defaultMarkers = [
			{
				lat: studentInfo?.stud_lat,
				lng: studentInfo?.stud_lon,
			},
			{
				lat: item?.busstop_in_lat,
				lng: item?.busstop_in_lon,
			},
			{
				lat: item?.busstop_out_lat,
				lng: item?.busstop_out_lon,
			}
		
		];
	}
	
	// Reference to the map instance
	const getMapZoom = () => {
		// Set a new zoom level to zoom into the markers
		if (mapRef.current) {
			const bounds = new window.google.maps.LatLngBounds();
			defaultMarkers.forEach((position) => {
				bounds.extend(position);  // Extend the bounds to include each marker
			});
			// Fit the map to the bounds of the markers and set zoom level
			mapRef.current.fitBounds(bounds);
			setZoom(mapRef.current.getZoom());  // Update the zoom level state
		}
	}
	
	// Function to handle map load and set map reference
	const onLoad = (map) => {
		mapRef.current = map;
		getMapZoom()
	};
	
	useEffect(() => {
		getMapZoom()
	}, [defaultMarkers, getMapZoom])
	
	//draggable
	const onMarkerDragEnd = (event, markerLabel) => {
		const newLat = event.latLng.lat();
		const newLng = event.latLng.lng();
		
		// Αλλάζουν τα markers σύμφωνα με τα νεα pins που ορίζει ο χρήστης
		// setMarkers((prevMarkers) => prevMarkers.map((marker) => marker.label === markerLabel ? {
		// 	...marker,
		// 	position: { lat: newLat, lng: newLng }
		// } : marker));
		
		console.log(`Marker ${markerLabel} dragged to: Latitude: ${newLat}, Longitude: ${newLng}`);
		setSelectedLat(newLat);
		setSelectedLng(newLng);
	};
	
	//saveMarkers
	const saveMarkers = (type) => {
		// console.log('studentInfo = ',studentInfo,'item = ', item, 'markers = ',markers);
		axios({
			method: 'post',
			data: { afm: afm, studentName: studentName, type: type, newLat: selectedLat, newLng: selectedLng, editLat:studentInfo?.stud_lat,editLng:studentInfo?.stud_lon, routeType:routeType },
			headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
			url: `${API}/el/pde/store/maps`,
		}).then(response => {
			console.log(response);
		}).catch(error => {
		
		})
		// if (type === 'point') {
		//
		// } else if (type === 'allRoutes') {
		//
		// } else { //allChildren
		//
		// }
		console.log(studentInfo, item, markers);
		//axios call
		onClose()
	}
	
	return (
		<Box>
			<PageContentWrapper>
				<Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
					<Box>
						<Typography sx={{ fontWeight: 'bold' }}> {studentName}</Typography>
						<Typography sx={{ fontWeight: 'bold' }}> {item?.route_title}</Typography>
					</Box>
					<IconButton onClick={onClose}>
						<CloseIcon/>
					</IconButton>
					{/*<CloseIcon sx={{color:'red'}} onClick={onClose}/>*/}
				</Box>
				{draggableValue && (
					<PageTitle
						title={t(routeType)}
						withDivider
					/>
				)}
				<Box
					sx={{
						minWidth: { xs: '300px', sm: '500px', md: '900px', lg: '900px', xl: '1000px' },
						width: '100%'
					}}
				>
					<LoadScript googleMapsApiKey={API_KEY}>  {/* Replace with your API key */}
						<GoogleMap
							center={initialCenter}
							mapContainerStyle={containerStyle}
							onLoad={onLoad}  // Set the onLoad callback to center the map
							zoom={zoom}  // Initial zoom level (will be adjusted by fitBounds)
						>
							{/* Render markers at the defined positions */}
							{markers.map((item, index) => (<Marker
								key={index}
								position={item.position}
								label={{
									text: item.label, // Text for the label
									className: 'custom-marker-label', // Add custom class
								}}
								title={item.title}  // Set the title (tooltip on hover)
								
								// Different colored marker URLs  like red-dot.png, blue-dot.png, green-dot.png, purple-dot.png, yellow-dot.png, etc.
								
								icon={{
									url: item.url,
								}}
								draggable={item.draggable}
								onDragEnd={(event) => onMarkerDragEnd(event, item.label)}
							/>))}
						</GoogleMap>
					</LoadScript>
				</Box>
				{/*TODO:: 3 buttons για αποθηκευση : 1 για το συγκεκριμενο δρομολογιο και παιδι, 1 για ολα τα δρομολόγια τουμ παιδιού, 1 για όλα τα δρομολόγια όλων των παιδιών.*/}
				
				{draggableValue &&
					<Box sx={{ marginTop: '10px' }}>
						<Grid sx={{ display: 'flex' }}>
							<Box sx={{
								height: '20px',
								width: '20px',
								marginBottom: '15px',
								marginRight: '5px',
								border: '1px solid red',
								backgroundColor: '#fd7567'
							}}>
							</Box>
							<Typography> {t('Bus stops & Finally')} </Typography>
						</Grid>
						<Grid sx={{ display: 'flex' }}>
							<Box sx={{
								height: '20px',
								width: '20px',
								marginBottom: '15px',
								marginRight: '5px',
								border: '1px solid blue',
								backgroundColor: '#6991fd'
							}}>
							</Box>
							<Typography>  {t('Stated home address')} </Typography>
						</Grid>
						<Grid sx={{ display: 'flex' }}>
							<Box sx={{
								height: '20px',
								width: '20px',
								marginBottom: '15px',
								marginRight: '5px',
								border: '1px solid green',
								backgroundColor: '#17a773'
							}}>
							</Box>
							<Typography>  {t('Selected home address')} </Typography>
						</Grid>
					</Box>
					
				}
				<Box sx={{ marginTop: '20px', display: 'flex', flexWrap: 'wrap', gap: '10px', minWidth: '300px' }}>
					{draggableValue &&
						<>
							<Button variant="outlined" startIcon={<Save/>} onClick={() => saveMarkers('point')}>
								{t('Save Point')}
								{/*apothikeusi dromologiou*/}
							</Button>
							<Button variant="outlined" startIcon={<Save/>} onClick={() => saveMarkers('allRoutes')}>
								{t('Save Point For All Routes For Child')}
								{/*apothikeusi gia ola ta dromologia tou paidiou*/}
							</Button>
							<Button variant="outlined" startIcon={<Save/>} onClick={() => saveMarkers('allChildren')}>
								{t('Save Point For All Routes For All Children')}
								{/*apothikeysh gia ola ta dromoligia olwn twn paidiwn*/}
							</Button>
						</>
					}
				</Box>
			
			</PageContentWrapper>
		</Box>
	)
};