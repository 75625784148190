import * as Namespaces from './namespaces'

const locales = {
	[Namespaces.ANNOUNCEMENTS]: {
		el: {
			'Announcements' : 'Ανακοινώσεις',
			'announcements!' : 'ανακοινώσεις!',
			'Announcement' : 'Ανακοίνωση',
			'announcement!' : 'ανακοίνωση!',
			'Announcement Create' : 'Δημιουργία Ανακοίνωσης',
			'Announcement Edit' : 'Επεξεργασία Ανακοίνωσης',
			'Add' : 'Προσθήκη',
			'Return' : 'Επιστροφή',
			'Important' : 'Σημαντική',
			'expirationDate' : 'Ημ/νία Λήξης',
			'From Date' : 'Ημ/νία ανάρτησης',
			'Type' : 'Τύπος',
			'Title' : 'Τίτλος',
			'Required' : 'Απαιτείται',
			'Send e-mail' : 'Αποστολή e-mail',
			'Body' : 'Κείμενο',
			'Announcement created successfully!' : 'Η Ανακοίνωση δημιουργήθηκε με επιτυχία!',
			'Announcement updated successfully!' : 'Η Ανακοίνωση ενημερώθηκε με επιτυχία!',
			'There are not announcements!' : 'Δεν υπάρχουν ανακοινώσεις!',
			'Please wait...' : 'Παρακαλώ περιμένετε...',
			'News' : 'Ενημερώσεις',
			'All data related to this announcement will be deleted!' : 'Όλα τα δεδομένα που σχετίζονται με αυτήν την ανακοίνωση θα διαγραφούν!',
			'Announcement deleted successfully!' : 'Η ανακοίνωση διαγράφηκε με επιτυχία!',
			'Unable to delete announcement!' : 'Αδυναμία διαγραφής ανακοίνωσης!',
			'Delete' : 'Διαγραφή',
			'Actions' : 'Ενέργειες',
			'Show more...':'Δείτε περισσότερα...',
			'Announcement Details' : 'Πληροφορίες Ανακοίνωσης',
			'Edit' : 'Επεξεργασία',
			'You have' : 'Έχετε',
			'schYear' : 'Σχ.Χρονιά',
		
			
		},
		en: {
			'expirationDate' : 'Expiration Date',
			'schYear' : 'Sch.Year'
		}
	},
}

export default locales