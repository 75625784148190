import { NavigateBefore } from "@mui/icons-material";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";
import axios from "axios";
import dayjs from "dayjs";
import { enqueueSnackbar } from "notistack";
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import PageMenu from "../../../components/custom/app/pageMenu/pageMenu";
import PageContentWrapper from "../../../components/mui/app/pageContentWrapper/pageContentWrapper";
import QuickViewInfoWrap from "../../../components/mui/app/quickViewInfoWrap/quickViewInfoWrap";
import SkeletonLoaderWrapper from "../../../components/mui/general/skeletonLoaderWrapper/skeletonLoaderWrapper";
import { API } from "../../../helpers/constants";
import { handleAxiosErrors } from "../../../helpers/errorHandling";
import { access_user, view_mails } from "../../../helpers/rights";
import useUserData from "../../../hooks/user/useUserData";
import { withPermissionHOC } from "../../permission/components/permissionHOC/permissionHOC";
import { MAILS } from "../locales/namespaces";

const Details = (props) => {
	const { id } = useParams();
	const { t } = useTranslation(MAILS)
	const {token} = useUserData();
	const [data,setData] = useState();
	const [loading,setLoading] = useState(false);
	
	useEffect(() => {
		setLoading(true)
		axios({
			method: 'get',
			headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
			url: `${API}/en/pde/mail/details/` + id,
		}).then(response => {
			console.log(response.data[0])
			setData(response.data[0]);
			setLoading(false);
		}).catch((error) => {
			setLoading(false);
			enqueueSnackbar(handleAxiosErrors(error, t('An error occurred!'), {
				variant: 'warning',
				autoHideDuration: 1000,
			}))
		})
	}, []);
	return (
		<Fragment>
			<Box className={'mail_details'} sx={{ marginTop: '20px' }}>
				<Box
					display={'flex'}
					flexWrap={'wrap'}
					justifyContent={'space-between'}
					gap={1}
				>
					<PageMenu>
						<Button
							component={Link}
							to={`/mails`}
							startIcon={<NavigateBefore/>}
						>
							{t('Return')}
						</Button>
					</PageMenu>
				</Box>
				<SkeletonLoaderWrapper loading={loading}>
				<Box sx={{width:'50%',placeSelf:'center'}}>
					<PageContentWrapper>
						<QuickViewInfoWrap
							label={t('Date')}> {dayjs(data?.Date).format('DD/MM/YYYY')}  </QuickViewInfoWrap>
						<QuickViewInfoWrap
							label={t('Send to')}>{data?.SendTo} </QuickViewInfoWrap>
						<QuickViewInfoWrap
							label={t('Subject')}> {data?.Subject} </QuickViewInfoWrap>
						<Divider/>
						<Box dangerouslySetInnerHTML={{ __html: data?.Body }}/>
					</PageContentWrapper>
				</Box>
				</SkeletonLoaderWrapper>
			</Box>
		</Fragment>
	);
};

// export default Details
export default withPermissionHOC(Details, [view_mails])