import { routeCreator } from "../../../helpers/routing/routeCreator";
import Index from "../pages";
import Details from "../pages/details";


export const ROUTE_MAILS_INDEX = routeCreator(Index, '/mails', 'Mails', 'page.address', {exact: true})
export const ROUTE_MAILS_DETAILS = routeCreator(Details, '/mails/:id', 'Mails', 'page.address', {exact: true})

const router = {
    ROUTE_MAILS_INDEX,
    ROUTE_MAILS_DETAILS
}

export default router