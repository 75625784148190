import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {
	DataGridPremium,
	GRID_AGGREGATION_ROOT_FOOTER_ROW_ID,
	GridActionsCellItem,
	GridToolbar
} from "@mui/x-data-grid-premium";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Delete from "../../../components/app/delete/delete";
import PageMenu from "../../../components/custom/app/pageMenu/pageMenu";
import PageContentWrapper from "../../../components/mui/app/pageContentWrapper/pageContentWrapper";
import PageTitle from "../../../components/mui/app/pageTitle/pageTitle";
import Modal from "../../../components/mui/general/modal/modal";
import { CustomPagination } from "../../../components/mui/grids/dataGridCustom/customPagination";
import DataGridWrapper from "../../../components/mui/grids/dataGridWrapper/dataGridWrapper";
import { API } from "../../../helpers/constants";
import { muiGridLocales } from "../../../helpers/muiGridLocales";
import { access_announcements, access_user } from "../../../helpers/rights";
import { getRouteUrl } from "../../../helpers/routing/getRouteUrl";
import { useMuiGridServerSide } from "../../../hooks/muiGrid/useMuiGridServerSide";
import { withPermissionHOC } from "../../permission/components/permissionHOC/permissionHOC";
import { ROUTE_PAGE_USERS_DETAILS, ROUTE_PAGE_USERS_EDIT } from "../../users/routes/routes";
import { ANNOUNCEMENTS } from "../locales/namespaces";
import { ROUTE_ANNOUNCEMENT_EDIT, ROUTE_ANNOUNCEMENTS_DETAILS } from "../routes/routes";

const AnnouncementsIndex = () => {
	const { t } = useTranslation(ANNOUNCEMENTS)
	const [isDeletionVisible, setIsDeletionVisible] = useState(false)
	const [announceTitle,setAnnounceTitle]=useState();
	const [id,setId] = useState();
	
	useEffect(() => {
		document.title = t('Announcements')
	}, [])
	
	const {
		isLoading,
		data,
		total,
		page,
		pageSize,
		onPaginationModelChange,
		onSortModelChange,
		onFilterModelChange,
		refreshGrid
	} = useMuiGridServerSide({
		url: `messages/indexTable`,
		initialPageSize: 50,
		initialSortModel: [{ name: 'id', direction: 'descending' }]
	})
	
	const handleDeletionModal = (visible) => {
		setIsDeletionVisible(visible)
	}
	const columns = useMemo(() => {
		return [
			{
				headerName: t('Actions'),
				field: 'actions',
				type: 'actions',
				getActions: (params) => {
					if (params.id === GRID_AGGREGATION_ROOT_FOOTER_ROW_ID) {
						return []
					}
					return [
						<>
							<GridActionsCellItem
								component={Link}
								to={getRouteUrl(ROUTE_ANNOUNCEMENT_EDIT, { id: params.id })}
								icon={
									<Tooltip title={t('Edit')}>
										<IconButton>
											<EditIcon/>
										</IconButton>
									</Tooltip>
								}
								color={'info'}
								label={t('Edit')}>
							</GridActionsCellItem>
							<GridActionsCellItem
								onClick={() =>{handleDeletionModal(true);setAnnounceTitle(params.row.Subject); setId(params.id)}}
								icon={
									<Tooltip title={t('Delete')}>
										<IconButton>
											<DeleteIcon/>
										</IconButton>
									</Tooltip>
								}
								label={t('Delete')}>
							
							</GridActionsCellItem>
						</>
					]
				}
			},
			{
				field: 'DtFrom',
				headerName: t('From Date'),
				description: t('From Date'),
				flex: 1,
				minWidth: 100,
				type: 'date',
				aggregable: false,
				valueFormatter: (value, row, column, apiRef) => {
					return (value ? dayjs(value).format('DD/MM/YYYY') : null)
				}
			},
			{
				field: 'ExpiredDate',
				headerName: t('expirationDate'),
				description: t('expirationDate'),
				flex: 1,
				minWidth: 100,
				type: 'date',
				aggregable: false,
				valueFormatter: (value, row, column, apiRef) => {
					return (value ? dayjs(value).format('DD/MM/YYYY') : null)
				}
			},
			{
				field: 'Subject',
				headerName: t('Title'),
				minWidth: 400,
				flex: 1,
				renderCell: ({ row }) => (
					<Link to={getRouteUrl(ROUTE_ANNOUNCEMENTS_DETAILS, {
						id: row.id
					})}>
						{row.Subject}
					</Link>
				)
			},
			{
				field: 'Type',
				headerName: t('Type'),
				minWidth: 180,
				flex: 1,
			},
			{
				field: 'schYear',
				headerName: t('schYear'),
				minWidth: 180,
				flex: 1,
			},
			
			{
				field: 'isImportant',
				headerName: t('Important'),
				minWidth: 50, flex: 1,
				type: 'boolean',
				valueGetter: (value, row, column, apiRef) => value === '1'
			},
			{
				field: 'SendEmail',
				headerName: t('Send e-mail'),
				minWidth: 50, flex: 1,
				type: 'boolean',
				valueGetter: (value, row, column, apiRef) => value === '1'
			}
		]
	}, [t])
	
	return (
		<Box className={'announcement_index'} sx={{ marginTop: '10px' }}>
			<PageMenu>
				<Button
					component={Link}
					to={'/announcement/create'}
					startIcon={<AddCircleOutlineIcon/>}
				>
					{t('Add')}
				</Button>
			</PageMenu>
			<PageContentWrapper>
				<PageTitle
					title={t('Announcements')}/>
				<DataGridWrapper sx={{ height: 700 }}>
					<DataGridPremium
						columns={columns}
						density={'compact'}
						rows={data}
						pagination
						paginationModel={{ page, pageSize }}
						paginationMode="server"
						onPaginationModelChange={onPaginationModelChange}
						rowCount={Number(total)}
						pageSizeOptions={[5, 10, 15, 20, 50, 100]}
						sortingMode="server"
						onSortModelChange={(sortModel) => onSortModelChange(sortModel)}
						filterMode="server"
						onFilterModelChange={onFilterModelChange}
						loading={isLoading}
						localeText={muiGridLocales('el')}
						slots={{
							pagination: CustomPagination,
							toolbar: GridToolbar,
						}}
					/>
				</DataGridWrapper>
			</PageContentWrapper>
			
			
			<Modal
				show={isDeletionVisible}
			>
				<Delete
					objectId={id}
					objectLabel={t('Announcement')}
					objectLabelValue={announceTitle}
					deletionUrl={`${API}/en/messages/remove/`+ id}
					redirectUrl={`/announcements`}
					warningMessage={t('All data related to this announcement will be deleted!')}
					successMessage={t('Announcement deleted successfully!')}
					failureMessage={t('Unable to delete announcement!')}
					handleModal={handleDeletionModal}
					onClose={() => {setIsDeletionVisible(false); refreshGrid();}}
				/>
			</Modal>
		</Box>
		
	);
};

// export default AnnouncementsIndex
export default withPermissionHOC(AnnouncementsIndex, [access_announcements])