import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React from 'react';



const LoginCard = (props) => {
	return (
		<Card sx={{ height: '50%', borderRadius: 0, backgroundColor:  '#f0eee1',textAlign:'center' }} elevation={0}>
			<Box sx={{ paddingLeft:'3%',paddingRight:'3%',height:'95%'}}>
				<Box sx={{ display: 'flex', flexDirection: 'column' }}>
					<CardContent>
						<Typography
							variant="subtitle1"
							component="div"
							sx={{ color: 'text.secondary' }}
						>
							{props.title}
						</Typography>
					</CardContent>
					<Typography>
						{props.text}
					</Typography>
				</Box>
				<CardMedia
					component="img"
					sx={{ width: 269, justifySelf: 'center' }}
					image={props.src}
					alt="Live from space album cover"
				/>
			</Box>
		</Card>
	);
};

export default LoginCard