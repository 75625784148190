import { routeCreator } from "../../../helpers/routing/routeCreator";
import Index from "../pages";


export const ROUTE_ADDRESS_CHANGES_INDEX = routeCreator(Index, '/address/changes', 'Address', 'page.address', {exact: true})

const router = {
    ROUTE_ADDRESS_CHANGES_INDEX
}

export default router