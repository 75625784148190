import axios from "axios";
import { enqueueSnackbar } from "notistack";
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { API } from "../../../helpers/constants";
import { handleAxiosErrors } from "../../../helpers/errorHandling";
import { useMuiGridServerSide } from "../../../hooks/muiGrid/useMuiGridServerSide";
import { PARAMETERS, SECURITYROLES } from "../locales/namespaces";

const useParameterSchYear = (props) => {
	const { t } = useTranslation(PARAMETERS);
	const [load, setLoad] = useState(false)
	
	const {
		isLoading,
		data,
		total,
		page,
		pageSize,
		onPaginationModelChange,
		onSortModelChange,
		onFilterModelChange,
		refreshGrid
	} = useMuiGridServerSide({
		url: 'pde/parameters/schYear/index',
		initialPageSize: 50,
		initialSortModel: [{ name: 'id', direction: 'descending' }]
	})
	
	const store = (newRow) => {
		axios.post(`${API}/en/pde/parameters/schYear/store`, { newRow }).then(res => {
			enqueueSnackbar(t(`School Year created successfully!`), {
				variant: 'success',
				autoHideDuration: 1000,
			});
			refreshGrid()
		}).catch(error => {
			enqueueSnackbar(handleAxiosErrors(error, t('An error occurred!'), {
				variant: 'warning',
				autoHideDuration: 1000,
			}))
		})
	}
	
	const remove = (row) => {
		console.log(row)
		axios.post(`${API}/en/pde/parameters/schYear/remove`, { RowId: row }).then(res => {
			enqueueSnackbar(t(`School Year deleted successfully!`), {
				variant: 'success',
				autoHideDuration: 1000,
			});
			refreshGrid()
		}).catch(error => {
			enqueueSnackbar(handleAxiosErrors(error, t('An error occurred!'), {
				variant: 'warning',
				autoHideDuration: 1000,
			}))
		})
	}
	
	const update = (row) => {
		axios.post(`${API}/en/pde/parameters/schYear/update`, { RowId: row }).then(res => {
			enqueueSnackbar(t(`School Year updated successfully!`), {
				variant: 'success',
				autoHideDuration: 1000,
			});
			refreshGrid()
		}).catch(error => {
			enqueueSnackbar(handleAxiosErrors(error, t('An error occurred!'), {
				variant: 'warning',
				autoHideDuration: 1000,
			}))
		})
	}
	return {
		data,
		isLoading,
		refreshGrid,
		load,
		store,
		remove,
		update,
		total,
		page,
		pageSize,
		onPaginationModelChange,
		onSortModelChange,
		onFilterModelChange,
	}
};

export default useParameterSchYear