// @flow
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React from 'react';
import useUserData from "../../../hooks/user/useUserData";

type Props = {

};

const DirectorDashboard = (props: Props) => {
	const {username} = useUserData();
	return (
		<Box sx={{ textAlign: 'center', backgroundColor: 'lightblue', width: '100%' }}>
			<Typography sx={{ fontSize: '20px' }}>{username}</Typography>
		</Box>
	);
};

export default DirectorDashboard