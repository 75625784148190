import { routeCreator } from "../../../helpers/routing/routeCreator";
import parameters from "../pages/parameters";



export const ROUTE_PAGE_PARAMETERS_INDEX = routeCreator(parameters, '/parameters', 'Parameters', 'page.parameters', {exact: true})


const router = {
    ROUTE_PAGE_PARAMETERS_INDEX,
    
}

export default router