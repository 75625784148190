// @flow
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useMemo } from 'react';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PageContentWrapper from "../../../components/mui/app/pageContentWrapper/pageContentWrapper";
import PageTitle from "../../../components/mui/app/pageTitle/pageTitle";
import DataGridWrapper from "../../../components/mui/grids/dataGridWrapper/dataGridWrapper";
import EditableMUIDataGrid from "../../../components/mui/grids/editableMUIDataGrid/editableMUIDataGrid";
import { access_roles, access_parameters } from "../../../helpers/rights";
import { getRouteUrl } from "../../../helpers/routing/getRouteUrl";
import { withPermissionHOC } from "../../permission/components/permissionHOC/permissionHOC";
import { ROUTE_PAGE_SECURITY_ROLES_DETAILS } from "../../usersSecurityRoles/routes/routes";
import useParameterSchYear from "../hooks/useParameterSchYear";
import { PARAMETERS } from "../locales/namespaces";

type Props = {

};

const Parameters = (props: Props) => {
	const {t} = useTranslation(PARAMETERS)
	const {
		data,
		isLoading,
		refreshGrid,
		store,
		remove,
		update,
		total,
		page,
		pageSize,
		onPaginationModelChange,
		onSortModelChange,
		onFilterModelChange,
	} = useParameterSchYear()
	
	const newRowModel = {
		schYear: '',
		isDefault: false,
	}
	
	const columns = useMemo(() => [
		
		{
			field: 'schYear',
			headerName: t('Sch. Year'),
			width: 150, maxWidth: 300, flex: 1,
			editable: true,
		},
		{
			field: 'isDefault',
			headerName: t('Default'),
			width: 90, maxWidth: 180, flex: 1,
			type: 'boolean',
			editable: true,
			valueGetter: (value, row, column, apiRef) => value === '1'
		}
	
	], [t])
	return (
		<Box className="parameters_index">
			<PageContentWrapper> {/* Wrapper component που δημιουργεί συγκεκριμένο style γύρω από τα grid και τον τίτλο στην index. */}
				<PageTitle
					title={t('Sch. Years')}/> {/* Custom mui component για τον τίτλο σελίδων. Χρησιμοποιεί styles από συγκεκριμένα properties της παλέτας. Με το Property withDivider, μπορεί να εμφανίσει και divider κάτω από τον τίτλο, το οποίο επίσης παίρνει styles από την παλέτα. */}
				<DataGridWrapper sx={{ height: 600, maxWidth: '700px' }}>
					<EditableMUIDataGrid
						data={data}
						columns={columns}
						loading={isLoading}
						newRowModel={newRowModel}
						store={store}
						remove={remove}
						update={update}
						// hideEditButton={true}
						dataGridProOtherProps={{
							pagination: true,
							paginationModel: { page, pageSize },
							paginationMode: "server",
							onPaginationModelChange: onPaginationModelChange,
							rowCount: Number(total),
							pageSizeOptions: [5, 10, 15, 20, 50, 100],
							sortingMode: "server",
							onSortModelChange: (sortModel) => onSortModelChange(sortModel),
							filterMode: "server",
							onFilterModelChange: onFilterModelChange,
						}}
					/>
				</DataGridWrapper>
			</PageContentWrapper>
		</Box>
	);
};

// export default Parameters
export default withPermissionHOC(Parameters, [access_parameters])