import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {
	DataGridPremium,
	GRID_AGGREGATION_ROOT_FOOTER_ROW_ID,
	GridActionsCellItem,
	GridToolbar
} from "@mui/x-data-grid-premium";
import dayjs from "dayjs";
import React, { useMemo } from 'react';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PageMenu from "../../../components/custom/app/pageMenu/pageMenu";
import PageContentWrapper from "../../../components/mui/app/pageContentWrapper/pageContentWrapper";
import PageTitle from "../../../components/mui/app/pageTitle/pageTitle";
import QuickView from "../../../components/mui/general/quickView/quickView";
import { CustomPagination } from "../../../components/mui/grids/dataGridCustom/customPagination";
import DataGridWrapper from "../../../components/mui/grids/dataGridWrapper/dataGridWrapper";
import { muiGridLocales } from "../../../helpers/muiGridLocales";
import { access_user, view_address_changes } from "../../../helpers/rights";
import { getRouteUrl } from "../../../helpers/routing/getRouteUrl";
import { useMuiGridServerSide } from "../../../hooks/muiGrid/useMuiGridServerSide";
import { withPermissionHOC } from "../../permission/components/permissionHOC/permissionHOC";
import QuickDetailsUser from "../../users/Components/QuickDetails/QuickDetailsUser";
import { ROUTE_PAGE_USERS_DETAILS, ROUTE_PAGE_USERS_EDIT } from "../../users/routes/routes";
import { ADDRESSSCHANGES } from "../locales/namespaces";


const Index = () => {
	const {t} = useTranslation(ADDRESSSCHANGES)
	
	
	const {
		isLoading,
		data,
		total,
		page,
		pageSize,
		onPaginationModelChange,
		onSortModelChange,
		onFilterModelChange
	} = useMuiGridServerSide({
		url: 'pde/indexTable/addressChanges',
		initialPageSize: 50,
		initialSortModel: [{ name: 'id', direction: 'descending' }]
	})
	
	const columns = useMemo(() => {
		return [
			// {
			// 	headerName: t('Actions'),
			// 	field: 'actions',
			// 	type: 'actions',
			// 	getActions: (params) => {
			// 		if (params.id === GRID_AGGREGATION_ROOT_FOOTER_ROW_ID) {
			// 			return []
			// 		}
			// 		return [
			// 			<GridActionsCellItem
			// 				component={Link}
			// 				to={getRouteUrl(ROUTE_PAGE_USERS_EDIT, { id: params.id })}
			// 				icon={
			// 					<Tooltip title={t('Edit')}>
			// 						<IconButton>
			// 							<EditIcon/>
			// 						</IconButton>
			// 					</Tooltip>
			// 				}
			// 				color={'info'}
			// 				label={t('Edit')}>
			// 			</GridActionsCellItem>
			// 		]
			// 	}
			// },
			{
				field: 'ParentAFM',
				headerName: t('Parent AFM'),
				minWidth: 150,
				flex: 1,
				// renderCell: ({ row }) => (
				// 	<Link to={getRouteUrl(ROUTE_PAGE_USERS_DETAILS, {
				// 		id: row.id
				// 	})}>
				// 		{row.LoginName}
				// 	</Link>
				// )
			},
			{
				field: 'childName',
				headerName: t('child name'),
				minWidth: 300,
				flex: 1,
				// renderCell: (params) => {
				// 	if (params.rowNode.type === 'group') {
				// 		return params.value
				// 	}
				// 	return (
				// 		<Button
				// 			variant="link"
				// 			// onClick={() => setUserQuickView({
				// 			// 	open: true,
				// 			// 	userId: params.row.id
				// 			// })}
				// 		>
				// 			{params.row.username}
				// 		</Button>
				// 	)
				// },
			},
			{
				field: 'newLng',
				headerName: t('Lng'),
				minWidth: 100, flex: 1,
			},
			{
				field: 'newLat',
				headerName: t('Lat'),
				minWidth: 100, flex: 1,
			},
			{
				field: 'EditLng',
				headerName: t('Old Lng'),
				minWidth: 100, flex: 1,
			},
			{
				field: 'EditLat',
				headerName: t('Old Lat'),
				minWidth: 100, flex: 1,
			},
			{
				field: 'LastModifiedAt',
				headerName: t('Date Change'),
				minWidth: 150, flex: 1,
				valueFormatter: (value, row, column, apiRef) => {
					return (value ? dayjs(value).format('DD/MM/YYYY') : null)
				}
			},
			{
				field: 'isDefault',
				headerName: t('Active'),
				minWidth: 50, flex: 1,
				type: 'boolean',
				valueGetter: (value, row, column, apiRef) => value === '1'
			}
		]
	}, [t])
	return (
	
		<Box className={'address_changes_index'} sx={{marginTop:'10px'}}>

			<PageContentWrapper>
				<PageTitle
					title={t('Changes of Addresses')}/>
				<DataGridWrapper sx={{ height: 700 }}>
					<DataGridPremium
						columns={columns}
						density={'compact'}
						rows={data}
						pagination
						paginationModel={{ page, pageSize }}
						paginationMode="server"
						onPaginationModelChange={onPaginationModelChange}
						rowCount={Number(total)}
						pageSizeOptions={[5, 10, 15, 20, 50, 100]}
						sortingMode="server"
						onSortModelChange={(sortModel) => onSortModelChange(sortModel)}
						filterMode="server"
						onFilterModelChange={onFilterModelChange}
						loading={isLoading}
						localeText={muiGridLocales('el')}
						slots={{
							pagination: CustomPagination,
							toolbar: GridToolbar,
						}}
					/>
				</DataGridWrapper>
			</PageContentWrapper>

		</Box>
	);
};

// export default Index
export default withPermissionHOC(Index, [view_address_changes])