import * as Namespaces from './namespaces'

const locales = {
	[Namespaces.MAILS]: {
		el: {
			'Send to' : 'Αποστολή σε',
			'Subject' : 'Θέμα',
			'Body' : 'Κείμενο',
			'Date' : 'Ημ/νία',
		},
		en: {
		
		}
	},
}

export default locales