import * as Namespaces from './namespaces'

const locales = {
	[Namespaces.DIRECTORS]: {
		el: {
			
		},
		en: {
		
		}
	},
}

export default locales