// @flow
import axios from "axios";
import React, { useEffect, useState } from 'react';
import useUserData from "../../../hooks/user/useUserData";

type Props = {};

const useRoutes = (props: Props) => {
	let { apiITYEToken } = useUserData();
	// const { afm } = useUserData()
	if(apiITYEToken === null){
		apiITYEToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImVyZ29sb2dpYyIsImlhdCI6MTcyNTUyODk0NCwiZXhwIjoxNzQxMDgwOTQ0fQ.43d8MR7i6yxvJcR4CNTagZtHg1lamF1d8v9vGrM32BA';
	}
	
	const [routesData, setRoutesData] = useState([]);
	const [loading, setLoading] = useState(false);
	const afm = '987654321';
	
	useEffect(() => {
		if (apiITYEToken) {
			let current = true;
			setLoading(true);
			axios.request({
				headers: {
					Authorization: `Bearer ${apiITYEToken}`
				},
				method: "GET",
				url: `https://api-devtransport-pde.cti.gr/api/openapi/contractor/routes?afm=` + afm
			}).then(response => {
				if (current) {
					setRoutesData(response.data.data);
				}
				setLoading(false);
			}).catch(error => {
				//TODO::enqueue error notification
				setLoading(false);
			})
			
			return () => {
				current = false;
			}
		}
	}, [])
	
	return {
		routesData,
		loading
	};
};

export default useRoutes