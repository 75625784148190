// @flow
import Grid from "@mui/material/Grid2";
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import Logo from "../../components/custom/general/logo/logo";
import { PAGE_LOGIN } from "../../locales/pages/namespaces";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Card, CardActionArea, CardContent, CardMedia, Typography } from "@mui/material";
import Form from "../../components/app/forms/login/form";
import LanguageSelect from "../../components/app/languageSelect/languageSelect";
import ForgotPassword from "../../components/app/dialogs/forgotPassword/forgotPassword";

import useUserAction from "../../hooks/user/useUserAction";
import useUserData from "../../hooks/user/useUserData";

import useLogin from "../../hooks/login/useLogin";
import './login.css'
import LoginCard from "./loginCard";

const Login = () => {
	const { t } = useTranslation(PAGE_LOGIN)
	const { checkAPIConnectivity } = useUserAction()
	const { loading, isLoggedIn } = useUserData()
	const { formik } = useLogin()
	const [openForgotPass, setOpenForgotPass] = useState(false)
	
	useEffect(() => {
		if (!isLoggedIn) checkAPIConnectivity()
	}, [checkAPIConnectivity, isLoggedIn])
	
	return (
		<Grid container>
			{/* 1η Στήλη */}
			<Grid
				size={{ sm: 3, md: 4 }}
				display={{ xs: 'none', sm: 'flex' }}
				sx={{ flexDirection: 'column' }}
			>
				<LoginCard
					title={'Αντικείμενο του έργου'}
					text={'Το έργο επικεντρώνεται στην ανάπτυξη ηλεκτρονικών υποδομών σύγχρονων υπηρεσιών και εφαρμογών, παρέχοντας σε γονείς και αναδόχους εύκολη πρόσβαση σε σημαντικές πληροφορίες, όπως δρομολόγια και στάσεις.'}
					src={`${process.env.PUBLIC_URL}/images/antikeimeno_ergou.png`}
				/>
				<LoginCard
					title={'Στόχος του έργου'}
					text={'Στόχος είναι η βελτίωση της αποτελεσματικότητας και της ευκολίας μεταφοράς, με υποστήριξη σε σχολεία, αναδόχους, κηδεμόνες και υπαλλήλους.Παράλληλα, διασφαλίζεται η αποδοτική διαχείριση και οργάνωση των μεταφορών από την Περιφέρεια Δυτικής Ελλάδας.'}
					src={`${process.env.PUBLIC_URL}/images/image.png`}
				/>
			
			</Grid>
			{/* 2η Στήλη */}
			<Grid
				size={{ xs: 12, sm: 6, md: 4 }}
			>
				<Box sx={{ backgroundColor: '#f0eee1', height: '100%' }}>
					<Box
						className={"login"}
						bgcolor={'background.default'}
					>
						<Box
							className={'login-box'}
							bgcolor={'#f0eee1'}
						>
							<Box p={1}>
								<LanguageSelect/>
							</Box>
							<Logo
								style={{
									paddingTop: '1rem',
									maxHeight: '140px',
									width: 'auto'
								}}
							/>
							<Box>
								<Box pb={2}>
									<Typography
										variant="h1"
										component={'div'}
										color={"secondary.main"}
										sx={{
											letterSpacing: 1.6,
											textAlign: 'center',
											textShadow: '0px 6px 4px rgba(0,0,0,0.2)'
										}}
									>
										{t("Student Portal")}
									</Typography>
								</Box>
								<Box>
									<Typography sx={{
										letterSpacing: 1.6,
										textAlign: 'center',
										textShadow: '0px 4px 4px rgba(0,0,0,0.2)'
									}}>
										Ψηφιακό Σύστημα Πολυεπίπεδης Πληροφόρησης Και Διαχείρισης της Μεταφοράς Μαθητών
										της Περιφέρειας Δυτικής Ελλάδας
									</Typography>
								</Box>
								<Form
									formik={formik}
									loading={loading}
								/>
							</Box>
							<Box>
								<Typography
									component={Button}
									onClick={() => setOpenForgotPass(true)}
									variant="body1"
									color={'secondary'}
								>
									{t("Forgot password? Click here.")}
								</Typography>
							</Box>
						</Box>
						<ForgotPassword
							open={openForgotPass}
							handleDialog={(newValue) => setOpenForgotPass(newValue)}
						/>
					</Box>
				</Box>
			</Grid>
			{/* 3η Στήλη */}
			<Grid
				size={{ sm: 3, md: 4 }}
				display={{ xs: 'none', sm: 'flex' }}
				sx={{ flexDirection: 'column' }}
			>
				
				<LoginCard
					title={'Oφέλη για Κηδεμόνες'}
					text={'Οι κηδεμόνες αποκτούν πλήρη ενημέρωση και έλεγχο για την μεταφορά των παιδιών τους,με άμεση πρόσβαση σε δεδομένα όπως οι στάσεις παραλαβής μέσω διαδραστικού χάρτη, και με δυνατότητα ελέγχου και δήλωσης της διεύθυνσής τους.'}
					src={`${process.env.PUBLIC_URL}/images/Κηδεμόνας.png`}
				/>
				<LoginCard
					title={'Οφέλη για Αναδόχους'}
					text={'Οι ανάδοχοι αποκτούν πλήρη εικόνα των δρομολογίων τους και άμεση πρόσβαση στις πραγματοποιηθείσες διαδρομές τους.'}
					src={`${process.env.PUBLIC_URL}/images/Aνάδοχοι.png`}
				/>
			
			</Grid>
		</Grid>
	)
}

export default Login