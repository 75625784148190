// @flow
import { Badge, styled, Typography } from "@mui/material";
import React, { useContext } from 'react';
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import {
	access_announcements,
	access_news,
	access_rights,
	access_roles,
	access_user,
	view_faqs,
	access_parameters,
	view_address_changes,
	view_mails,
	view_parent_dashboard,
	view_contractor_dashboard
} from "../../../../helpers/rights";
import { getRouteUrl } from "../../../../helpers/routing/getRouteUrl";
import { ROUTE_ADDRESS_CHANGES_INDEX } from "../../../../modules/addressChanges/routes/routes";
import {
	ROUTE_ANNOUNCEMENT_INDEX,
	ROUTE_ANNOUNCEMENT_NEWS
} from "../../../../modules/announcements/routes/routes";
import { ROUTE_FAQS_INDEX } from "../../../../modules/faqs/routes/routes";
import { ROUTE_MAILS_INDEX } from "../../../../modules/mails/routes/routes";
import { ROUTE_PAGE_PARAMETERS_INDEX } from "../../../../modules/parameters/routes/routes";
import PermissionHOC from "../../../../modules/permission/components/permissionHOC/permissionHOC";
import { ROUTE_PAGE_RIGHTS_INDEX } from "../../../../modules/rights/routes/routes";
import { ROUTE_PAGE_USERS_INDEX } from "../../../../modules/users/routes/routes";
import { ROUTE_PAGE_SECURITY_ROLES_INDEX } from "../../../../modules/usersSecurityRoles/routes/routes";
import { TitleContext } from "../../../../pages/main/content";

import {
	ROUTE_PAGE_HOME
} from "../../../../routers/routes";
import { useTranslation } from "react-i18next";
import { NAVIGATION } from "../../../../locales/components/namespaces";
import { menuStyle } from "../header/header";

type Props = {
	navItems: Array<{
		id: number,
		url: string,
		title: string
	}>
};

export const NotificationsIcon = (props: { fontSize: string }) => {
	return null;
}

const MainMenu = (props: Props) => {
	const { t } = useTranslation(NAVIGATION)
	const { count } = useContext(TitleContext)
	const NotificationButton = styled(Button)({
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		textTransform: 'none',
		color: '#1976d2',  // Customize color as needed
	});
	
	return (
		<Box sx={{ display: { xs: 'none', sm: 'none', md: 'flex' }, marginRight: 'auto', flexWrap: 'wrap' }}>
			<Box pr={2}>
				<PermissionHOC requiredPermissions={[view_parent_dashboard, view_contractor_dashboard]}>
					<Button
						component={Link}
						to={getRouteUrl(ROUTE_PAGE_HOME)}
						sx={{
							...menuStyle
						}}
					>
						{t('Home')}
					</Button>
				</PermissionHOC>
			</Box>
			<Box pr={2}>
				{/*TODO:: User PermissionHoc for user access when permissions are ready!*/}
				<PermissionHOC requiredPermissions={[access_announcements]}>
					<Button
						component={Link}
						to={getRouteUrl(ROUTE_ANNOUNCEMENT_INDEX)}
						sx={{ ...menuStyle }}
					>
						{t('Announcements')}
					</Button>
				</PermissionHOC>
			</Box>
			<Box pr={2}>
				<PermissionHOC requiredPermissions={[access_news]}>
					<NotificationButton
						component={Link}
						to={getRouteUrl(ROUTE_ANNOUNCEMENT_NEWS)}
						sx={{ ...menuStyle }}
					>
						{count > 0 &&
							<Badge badgeContent={count} color="error" sx={{ position: 'unset' }}>
								<NotificationsIcon fontSize="large"/>
							</Badge>
						}
						{t('Announcements')}
					</NotificationButton>
				</PermissionHOC>
			</Box>
			
			<PermissionHOC requiredPermissions={[access_user]}>
				<Box pr={2}>
					<Button
						component={Link}
						to={getRouteUrl(ROUTE_PAGE_USERS_INDEX)}
						sx={{ ...menuStyle }}
					>
						{t('Users')}
					</Button>
				</Box>
			</PermissionHOC>
			
			
			<PermissionHOC requiredPermissions={[access_roles]}>
				<Box pr={2}>
					<Button
						component={Link}
						to={getRouteUrl(ROUTE_PAGE_SECURITY_ROLES_INDEX)}
						sx={{ ...menuStyle }}
					>
						{t('Roles')}
					</Button>
				</Box>
			</PermissionHOC>
			
			
			<PermissionHOC requiredPermissions={[access_rights]}>
				<Box pr={2}>
					<Button
						component={Link}
						to={getRouteUrl(ROUTE_PAGE_RIGHTS_INDEX)}
						sx={{ ...menuStyle }}
					>
						{t('Rights')}
					</Button>
				</Box>
			</PermissionHOC>
			
			
			<PermissionHOC requiredPermissions={[access_parameters]}>
				<Box pr={2}>
					<Button
						component={Link}
						to={getRouteUrl(ROUTE_PAGE_PARAMETERS_INDEX)}
						sx={{ ...menuStyle }}
					>
						{t('Parameters')}
					</Button>
				</Box>
			</PermissionHOC>
			
			
			<PermissionHOC requiredPermissions={[view_address_changes]}>
				<Box pr={2}>
					<Button
						component={Link}
						to={getRouteUrl(ROUTE_ADDRESS_CHANGES_INDEX)}
						sx={{ ...menuStyle }}
					>
						{t('Addresses Changes')}
					</Button>
				</Box>
			</PermissionHOC>
			
			
			<PermissionHOC requiredPermissions={[view_mails]}>
				<Box pr={2}>
					<Button
						component={Link}
						to={getRouteUrl(ROUTE_MAILS_INDEX)}
						sx={{ ...menuStyle }}
					>
						{t('Mails')}
					</Button>
				</Box>
			</PermissionHOC>
			
			
			<PermissionHOC requiredPermissions={[view_faqs]}>
				<Box pr={2}>
					<Button
						component={Link}
						to={getRouteUrl(ROUTE_FAQS_INDEX)}
						sx={{ ...menuStyle }}
					>
						{t('FAQs')}
					</Button>
				</Box>
			</PermissionHOC>
		
		</Box>
	)
		;
};

export default MainMenu