import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import PageContentWrapper from "../../../components/mui/app/pageContentWrapper/pageContentWrapper";
import PageTitle from "../../../components/mui/app/pageTitle/pageTitle";
import { FAQS } from "../locales/namespaces";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

const Faqs = (props) => {
	const { t } = useTranslation(FAQS)
	
	const [expanded, setExpanded] = useState('panel1');
	
	const handleChange =
		(panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
			setExpanded(newExpanded ? panel : false);
		};
	
	return (
		<Box className={'faqs_index'} sx={{ marginTop: '10px' }}>
			<Grid container spacing={1}>
				<Grid size={{md:2, sm:0, xs:0}}>
				
				</Grid>
				<Grid size={{md:8, sm:12, xs:12}}>
					<PageContentWrapper>
						<PageTitle
							title={t('FAQs')}/>
						<Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon/>}
								aria-controls="panel1-content"
								id="panel1-header"
							>
								Ερώτημα 1: Επιχείρηση με έναρξη 02/2017 μπορεί να αιτηθεί λειτουργικές δαπάνες;
							</AccordionSummary>
							<AccordionDetails>
								Σύμφωνα με την 2η Τροποποίηση της Πρόσκλησης (σελ. 29) «Οι επενδυτικές δαπάνες που
								αναφέρονται επιγραμματικά στον Πίνακα 1 αφορούν τις λειτουργικές δαπάνες ΜΟΝΟ των υπό
								σύσταση και των νέων (που δεν έχουν κλείσει 2 διαχειριστικές χρήσεις) επιχειρήσεων». Ως
								εκ τούτου η επιχείρηση με έναρξη 02/2017, εφόσον έχει δύο κλεισμένες διαχειριστικές
								χρήσεις (μαζί με το 2018) δεν μπορεί να αιτηθεί λειτουργικές δαπάνες ως νέα επιχείρηση.
							</AccordionDetails>
						</Accordion>
						<Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon/>}
								aria-controls="panel2-content"
								id="panel2-header"
							>
								Ερώτημα 2: Πότε θα γίνει η έναρξη υποβολών του προγράμματος "Δημιουργικές επιχειρήσεις";
								Πότε προβλέπεται η λήξη υποβολών για το ίδιο πρόγραμμα;
							</AccordionSummary>
							<AccordionDetails>
								Οι ημερομηνίες έναρξης και λήξης υποβολής των προτάσεων, είναι:
								
								Έναρξη υποβολής προτάσεων: 02/04/2019 ώρα 13:00
								Λήξη υποβολής προτάσεων: 30/09/2019 ώρα 15:00 (υποέγκριση παράταση έως 04-11-2019)
							</AccordionDetails>
						</Accordion>
						{/*<Accordion defaultExpanded>*/}
						<Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon/>}
								aria-controls="panel3-content"
								id="panel3-header"
							>
								Ερώτημα 3: Πότε θα ανοίξει η φόρμα; Η αίτηση θα πραγματοποιηθεί online ή πρέπει να
								απευθυνθούμε σε κάποιο κέντρο;
							</AccordionSummary>
							<AccordionDetails>
								Έχει ήδη ανοίξει. Η αίτηση χρηματοδότησης (επενδυτική πρόταση) υποβάλλεται ηλεκτρονικά
								στο Πληροφοριακό Σύστημα Κρατικών Ενισχύσεων (Π.Σ.Κ.Ε.) που παρέχει το Υπουργείο
								Οικονομίας και Ανάπτυξης στην διεύθυνση www.ependyseis.gr με την ένδειξη: «Δράσεις
								Περιφερειών».
								
								Όλα τα έντυπα και οι οδηγίες υποβολής μέσω της ηλεκτρονικής πλατφόρμας (ΠΣΚΕ) βρίσκονται
								αναρτημένα στην ιστοσελίδα του προγράμματος Ενίσχυση των δημιουργικών επιχειρήσεων της
								Δυτικής Ελλάδας: https://www.pde.gov.gr/ependyseis/actions/creative.html» και στην
								ιστοσελίδα του ΕΠ «ΔΥΤΙΚΗ ΕΛΛΑΔΑ» 2014-2020: «www.dytikiellada.gr». Στις ίδιες
								ιστοσελίδες θα αναρτηθεί και αρχείο ερωτήσεων και απαντήσεων καθώς και όλες οι
								ανακοινώσεις που αφορούν το πρόγραμμα και προτείνουμε να τις επισκέπτεστε τακτικά.
							</AccordionDetails>
							{/*<AccordionActions>*/}
							{/*	<Button>Cancel</Button>*/}
							{/*	<Button>Agree</Button>*/}
							{/*</AccordionActions>*/}
						</Accordion>
					</PageContentWrapper>
				</Grid>
				<Grid size={{md:2, sm:0, xs:0}}>
				
				</Grid>
			</Grid>
		
		</Box>
	);
};

export default Faqs