import { routeCreator } from "../../../helpers/routing/routeCreator";
import DirectorDashboard from "../pages/directorDashboard";



export const ROUTE_PAGE_DIRECTORS_DASHBOARD = routeCreator(DirectorDashboard, '/directors', 'Parameters', 'page.parameters', {exact: true})


const router = {
    ROUTE_PAGE_DIRECTORS_DASHBOARD,
    
}

export default router