import axios from "axios";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { API } from "../../../helpers/constants";
import { handleAxiosErrors } from "../../../helpers/errorHandling";
import useUserData from "../../../hooks/user/useUserData";
import { PARENTS } from "../locales/namespaces";
import useSchYear from "./useSchYear";

const useParent = (props: Props) => {
	let { apiITYEToken } = useUserData();
	console.log(apiITYEToken);
	const {schYear} = useSchYear();
	
	// const { afm } = useUserData()
	
	// if(apiITYEToken === null){
	// 	 apiITYEToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImVyZ29sb2dpYyIsImlhdCI6MTcyNTUyODk0NCwiZXhwIjoxNzQxMDgwOTQ0fQ.43d8MR7i6yxvJcR4CNTagZtHg1lamF1d8v9vGrM32BA';
	// }
	// const apiITYEToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImVyZ29sb2dpYyIsImlhdCI6MTcyNTUyODk0NCwiZXhwIjoxNzQxMDgwOTQ0fQ.43d8MR7i6yxvJcR4CNTagZtHg1lamF1d8v9vGrM32BA';
	
	const [parentData, setParentData] = useState([]);
	const [loading, setLoading] = useState(false);
	const afm = '123456789';
	const { token } = useUserData()
	const {t} = useTranslation(PARENTS);
	
	
	const removeSpaces = (str) => str.replace(/\s+/g, '');
	
	const cleanArrayData = (dataArray, propertyName) => {
		return dataArray.map((item) => {
			if (item[propertyName] && typeof item[propertyName] === "string") {
				return {
					...item,
					[propertyName]: removeSpaces(item[propertyName]), // Αφαιρεί τα κενά
				};
			}
			return item; // Επιστρέφει το αντικείμενο ως έχει αν δεν χρειάζεται επεξεργασία
		});
	};
	
	useEffect(() => {
		if (apiITYEToken) {
			let current = true;
			setLoading(true);
			axios.request({
				headers: {
					Authorization: `Bearer ${apiITYEToken}`
				},
				method: "GET",
				url: `https://api-devtransport-pde.cti.gr/api/openapi/parent?afm=` + afm
			}).then(response => {
				if (current) {
					// setParentData(response.data.data);
					const modifiedData = response.data.data.map((item, index) => ({
						...item,
						id: index,  // Adding an 'id' based on the index (you can generate unique ids)
					}));
					const cleanedData = cleanArrayData(modifiedData, "schyear");
					
					const filteredItems = cleanedData.filter((item) => item.schyear === `${schYear}`);
					setParentData(filteredItems);
				
				}
				setLoading(false);
			}).catch(error => {
				//TODO::enqueue error notification
				setLoading(false);
			})
			
			return () => {
				current = false;
			}
		}
		
	}, [apiITYEToken, afm,schYear])
	
	useEffect(() => {
		if (parentData.length > 0){
			axios({
				method: 'post',
				headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
				url: `${API}/en/pde/child/store`,
				data: {'parentData':parentData,'afm':afm}
			}).then(response => {
				console.log(response.data[0])
				// setData(response.data[0]);
				setLoading(false);
			}).catch((error) => {
				setLoading(false);
				enqueueSnackbar(handleAxiosErrors(error, t('An error occurred!'), {
					variant: 'warning',
					autoHideDuration: 1000,
				}))
			})
		}
	}, [parentData]);
	
	return {
		parentData,
		afm,
		loading
	};
	
};

export default useParent