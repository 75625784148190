import axios from "axios";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { API } from "../../../helpers/constants";
import { handleAxiosErrors } from "../../../helpers/errorHandling";
import useUserData from "../../../hooks/user/useUserData";
import { ANNOUNCEMENTS } from "../../announcements/locales/namespaces";
import { USERS } from "../../users/locales/namespaces";



const useSchYear = (props) => {
	const [schYear,setSchYear] = useState();
	const { t } = useTranslation(USERS)
	const {token} = useUserData();
	
	useEffect(() => {
		axios({
			method: 'get',
			headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
			url: `${API}/en/pde/parameters/schYear`,
		}).then(response => {
			console.log(response)
			setSchYear(response.data);
		}).catch((error) => {
			enqueueSnackbar(handleAxiosErrors(error, t('An error occurred!'), {
				variant: 'warning',
				autoHideDuration: 1000,
			}))
		})
	}, []);
	
	return {
		schYear
	};
};

export default useSchYear

